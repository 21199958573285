
import thumbsUp from "../assets/icons/thumbs-icon.svg";


export const useExport = () => {


  return {

    thumbsUp,
   
};
}