// import { log } from "console";
// import React, { useEffect, useState } from "react";

// const useMediaQuery = (query:string) => {
//   const [matches, setMatches] = useState(false);
//   console.log(matches);
//   useEffect(() => {
//     const matchQueryList = window.matchMedia(query);
    
//     function handleChange(e:any) {
//       setMatches(e.matches);
//     }
//     matchQueryList.addEventListener("change", handleChange);
//     return () => {
//       matchQueryList.removeEventListener("change", handleChange);
//     };


//   }, [query]);
//   return matches;
// };

// export default useMediaQuery;


import { useState, useEffect } from "react";

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
};

export default useMediaQuery;
