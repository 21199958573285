




export const SelectedLink = {
  Home : "Home",
  About : "About",
  Services : "Services",
  Progress : "Progress",
  Projects : "Projects",
  Contact : "Contact",
}

